body{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.logo-ico-full{
    width: 155px;
}
.sidebar-ico{
    width: 40px;
}
.db-main{
    background: linear-gradient(121.64deg, #59EBFF -17.07%, #59EBFF -17.06%, #00A3FF -17.05%, #00054E 100.17%);
    height: 100vh;
    width: 100%;
}
.sidebar{
    height: 100vh;
    box-shadow: -1px 4px 18px 0px #00000040;
    /* backdrop-filter: blur(4px) !important; */
}
.sidebar_blur {
  color:white;
  filter: blur(4px);
}
.logo-ico-small{
    width: 50px;
}
.profile-ico{
    width: 40px;
    
}
.settings-ico{
    width: 30px;
    margin-top: 5px;
    margin-left: -25px;
}
.search-bar{
    border-radius: 25px 25px 25px 25px;
    width: 350px;
    border: none;
    height: 35px;
    margin-top: 10px;
    padding-left:10px;
    /* text-indent: -999px;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center; */
}
.search-bar:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}
.search-ico{
    width: 20px;
    margin-left: 320px;
    position: absolute;
    margin-top:-40px;
    /* top:4%; */
    
}
.right-sec{
    width: 100%;
}
.dashboard-list-sec{
    height:87vh;
    background: linear-gradient(289.01deg, #FFFFFF 3.45%, #FFFFFF 125.93%);
  /* background-color: #e8e8e8 !important; */
    box-shadow: 5px 4px 6px 0px #0000008F inset;


}
.dropdown-toggle::after{
    display: none!important;
}


.folder-card-body {
  background: linear-gradient(180deg, #ebe9e9 0%, #dddcdc 100%);
    border-radius: 10px;
    padding: 10px;
    width: 270px;
  }

  .folder-icon {
    width: auto;
    height: 25px;
    margin-top: 2px;
  }

  .folder-title {
    font-size: 20px;
    font-weight: 700;
    white-space: nowrap;
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
.all-files{
  height: 325px;
  overflow-y: scroll;
}
  .show-folder {
    background: transparent;
    border: none !important;
    margin-right: 15px;
  }
  .flex-fill-dash{
    width: 33%;
  }
  /* .file-items{
    background-color: #fff;
    
  } */
  .flex-file-dash {
    padding-left: 18%;
    width: 66%;
  }
  .text-limit {
    white-space: nowrap; 
    width: 200px; 
    overflow: hidden;
    text-overflow: ellipsis ; 
    text-align: justify;
    line-height: 19px;
    padding-top:1px;
  }
  .text-limit2 {
    white-space: nowrap; 
    width: 100px; 
    overflow: hidden;
    text-overflow: ellipsis ; 
    text-align: justify;
    line-height: 19px;
    padding-top:1px;
  }
  .file-items:hover{
    background-color: rgb(248,249,250)!important;
    /* border: 1px solid #bfe3f8; */
  }
  .buttons-icon{
    border:none;
    background-color: transparent;
    padding: 0;
    color: #BABABA;
  }

  .share-buttons-modals{
    background-color: transparent !important;
    transition: 0.2s !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #000 !important;
    width: 100% !important;
    text-align: center !important;
    border:1px solid #00A3FF !important;
    padding: 10px !important;
  }
  .share-buttons-modals:hover{
    background: linear-gradient(97.81deg, #000525 -2.78%, #0C3A72 61.17%, #3DA7FF 133.81%) !important;
    color: #FFFFFF !important;
  }
  .share-buttons-modals-div{
    margin-top: 10px !important;
  }
  .options-icons{
    color: #000;
  }

  .multiple-option{
    color:#454545;
    font-size: 18px;
    font-weight: 600;
  }
  .un-Select-btn{
    background-color: transparent;
    border: none;
  }
  .loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fff;
  }

  .new_loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .copyLinkSec{
    background-color: #ebf1ff;
    margin-right: 10px;
    margin-left: 10px;
    border:#e2eafc;
    border-radius: 10px;
  }
  #copyLnk{
    background-color: transparent !important;
    border: none;
    /* color:#b1b4ba; */
    padding-top: 0px !important;
  }
  #copyLnk:focus-visible{
    border: none!important;
    outline: -webkit-focus-ring-color auto 0px;
  }
  .copyLnk{
    width: 285px !important;
  }
  #copy-btn{
    border: none;
    padding: 6px 20px;
    color:#fff;
    font-size: 15px;
    border-radius: 5px;
    margin-top: 8px;
    margin-left: 6px;
  }
  .copy-btn-color-blue{
  border-radius: 74px;
  background: var(--Linear, linear-gradient(0deg, #000258 0%, #0053B4 100%));
  color: #fff;
  padding: 5px 15px;
  border: none;
  }
  .copy-btn-color-green{
    border-radius: 74px;
    background-color: #2ff49a;
    padding: 5px 15px;
    border: none;
  }
  .border-right-link{
    border-right: 1px solid #f9f9f9;
    padding: 10px;
    font-size:20px;
  }
  .text-limit:hover{
     color: #00A3FF;
     cursor: pointer;
  }


 .share_email input[type="email"]{
    padding: 10px !important;
    display: block !important;
    border-radius: 5px 0 0 5px !important;
    border: 1px solid lightgrey !important;
    background: none !important;
    width: 370px;
    color: black;
  }
   
  input[type="email"]:focus {
    outline: none !important;
  } 
  .add{
    background-color: transparent !important;
    transition: 0.2s !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #000 !important;
    text-align: center !important;
    border:1px solid #00A3FF !important;
    padding: 8px;
  }
  .add:hover{
    background: linear-gradient(97.81deg, #000525 -2.78%, #0C3A72 61.17%, #3DA7FF 133.81%) !important;
    color:#fff !important;
  }
  .add-small{
    
    background-color: transparent !important;
    transition: 0.2s !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #000 !important;
    text-align: center !important;
    border:1px solid #00A3FF !important;
    padding: 3.5px;
  }
  .add-small:hover{
    background: linear-gradient(97.81deg, #000525 -2.78%, #0C3A72 61.17%, #3DA7FF 133.81%) !important;
    color:#fff !important;
  }
.borderRadius{
  border-radius: 0px 5px 5px 0px !important;
}
input:focus {
 border-radius: 5px 5px 5px 5px;
}
li{
  list-style: none;
}
.bi-trash3:hover{
 color: #ff0000;
}
.bi-trash3{
  cursor: pointer;
}
.bi-download{
  cursor: pointer;
}
/* .email-style{
  wid
} */

.drop_down_width{
  --bs-dropdown-min-width :1rem !important;
}
.dropzone{
  border:#2e9bfe 3px dotted;
  border-radius: 5px;
  transition: opacity .15s linear;
  background: #ffffff !important;
  /* filter: blur(2px); */
  
}
/* .dropzone_image{
  background-image: url(../../Components/Images/loading.gif);

  
  z-index: 1 ;
} */


.image-container {
  position: absolute;
  /* margin-top: -500px;
  margin-left:430px ; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 250px;
  
}

/* .image-container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(../../Components/Images/DragDrop.gif);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  display: block;
  background-position: center !important;
   margin-left: 100%; 
} */

/* Additional styling for the foreground content */
/* .image-container::after {
  position: relative;
  z-index: 2;
  text-align: center;
  color: #fff;
  font-size: 24px;
  padding-top: 120px;
} */

/* .color{
  background-color: #fff;

} */
.color-shadow{
  filter: blur(1px);
}
.slick-prev:before, .slick-next:before{
  color:#8e8e8e !important;
}
.suggestionBox {
background-color: #ffffff;
margin-top: -22px;
width:350px;
border-radius:0 0 13px 13px;
position:absolute;
box-shadow: 5px 4px 6px 0px #3837374b ;
}

.createbucket{
  position: absolute;
  top:40%;
  text-align: center;
}
.bucket_input_field{
  position: absolute;
  top:70%;
  text-align: center;
}


/* .slick-slide{ */
  /* padding-bottom: 100px !important; */
  /* margin-left: 20px !important; */
  /* overflow-x: scroll; */
  /* margin-right: 10px !important; */
  /* margin-right: 2px;; */
/* } */
/* .slider_style{
  width: 960px;
} */
.button-hover_none:hover{
  border-color: transparent !important;
}
.progress-bari{
  
  width: 500px;
  
  /* background-color: #83c7ff; */
  position: absolute;
  right: 3%;
  bottom:2%;
}
/* .myprogress{
  background-color: #ffffff;
  width: 100%;
} */
/* .progress-bar{
  color: #492ff4;
} */

.landing_border{
  border-radius: 0 0px 80px 80px !important;
  box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.25) ;
}
.carousel-item .row {
  display: flex;
  justify-content: flex-end;
}
.react-multi-carousel-track {
  width:800px;
} 
@media screen and (max-width: 576px) {
  .react-multi-carousel-track {
    width:400px
  }
}

@media screen and (min-width: 576px) and (max-width:768px) {
  .react-multi-carousel-track {
    width: 450px;
  }
}

@media screen and (min-width: 768px) and (max-width:992px)  {
  .react-multi-carousel-track {
    width:490px;
  }
}

@media screen and  (min-width: 992px) and (max-width:1390px) {
  .react-multi-carousel-track {
    width:500px;
  }
}

@media screen and  (max-width:932px) {
  
}
tbody, td, tfoot, tr {border-color: #ededed !important}
th, thead{
  /* background-color: #dcd8d8 !important; */
  border-color: #fffefe !important;
  padding: 20px !important;

}
.file-hover:hover{
  background-color: #f3efefb6 !important;
}


.tbody-fixed {
  height: 375px !important;
  overflow-y: auto !important;
}
.drop-shadow {

  filter: drop-shadow(2px 2px 2px  rgba(0, 0, 30, 0.5));
  
  }
  .react-multiple-carousel__arrow{
    background: linear-gradient(121.64deg, #59EBFF -17.07%, #59EBFF -17.06%, #00A3FF -17.05%, #00054E 100.17%) !important;
    opacity: .8 !important;
  }
  .react-multiple-carousel__arrow:hover{
    background: linear-gradient(121.64deg, #59EBFF -17.07%, #59EBFF -17.06%, #00A3FF -17.05%, #00054E 100.17%) !important;
  opacity: 1 !important;
  }
  .react-multiple-carousel__arrow--right {
    right: 0% !important;
}
.react-multiple-carousel__arrow--left {
  left: 0% !important;
}
.input-group input:focus,
.form-control:focus {
  box-shadow: none;
  outline: none;
}
.bi-download:hover ,.bi-share:hover{
    color: #0867db;
}
.bi-x-lg:hover {
  color: #db0808;
  cursor: pointer;
}
.back-btn{
  background: linear-gradient(121.64deg, #59EBFF -17.07%, #59EBFF -17.06%, #00A3FF -17.05%, #00054E 100.17%) !important;
}
.bi-arrow-left-short{
  color: #ffffff;
}
.active{
  background: #f8f9fa ;
  border-radius: 5px;
}
/* .nav-link:focus{
  color:white !important;
} */
button {
  border-color:#2ff49a !important ;
}
.checkboxes:focus{
 box-shadow: 0 0 0 0.25rem rgba(250, 250, 250, 0.25) !important;
}
.zipping{
  background: #f1efef;
  box-shadow: -1px 4px 18px 0px #00000040;
  padding: 20px;
  width: 250px;
  
  /* background-color: #83c7ff; */
  position: absolute;
  right: 3%;
  bottom:2%;
}
.zipping .spinner-border{
  margin-top: -20px;
}
.access-bucket-main{
  height: 100vh;
}
.form-sec-left{
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100vh;
}
.form-card-acc-bkt{
  /* background-color: rgb(229, 229, 229);
  height: 400px;
  width: 50%; */
  
  height: 400px;
  width: 50%; 
  padding: 25px;
  background-color: #ecf0f3;
  box-shadow: 10px 10px 10px #d1d9e6, -10px -10px 10px #f9f9f9;
  border-radius: 12px;
  overflow: hidden;
  
  
}

.button-for-acc-bkt{
  background: linear-gradient(121.64deg, #59EBFF -17.06%, #00A3FF -17.05%, #00054E 100.17%),
              linear-gradient(0deg, #000000, #000000);
  width: 80%;
  padding: 10px 10px;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 27px;

}
/* .acc-bkt-form-field{
  width: 80%;
  height: 45px;
  color: #000000;
  padding-left: 5px;
} */

.acc-bkt-form-field:hover {
  opacity: 1;
  transition: 0.15s;

}
.acc-bkt-form-field {
  width:100%;
  padding: 10px;
  margin: 4px 0;
  font-size: 13px;
  letter-spacing: 0.15px;
  border: none;
  outline: none;
  font-family: "Montserrat", sans-serif;
  background-color: #ecf0f3;
  transition: 0.25s ease;
  border-radius: 8px;
  box-shadow: inset 2px 2px 4px #d1d9e6, inset -2px -2px 4px #f9f9f9;
}
.acc-bkt-form-field:focus {
  box-shadow: inset 4px 4px 4px #d1d9e6, inset -4px -4px 4px #f9f9f9;
}

.pad-top{
  margin-top: 50px;
}
.table_color{
  /* background-color: #bddade; */
  color:#fff;
  height: 40px;
}
.table-details{
  width: 80%;

}
.acc-bkt-td{
  height: 40px;
  padding-left: 5px;
  font-size: 22px;
}
.table-Header{
  background: linear-gradient(121.64deg, #59EBFF -17.06%, #00A3FF -17.05%, #00054E 100.17%),
              linear-gradient(0deg, #000000, #000000);
  filter: drop-shadow(2px 2px 2px  rgba(0, 0, 30, 0.5));

  
}
.webpath-style{
  /* position: absolute; */
  margin-top:7px;
  white-space: nowrap;
  max-width: 250px;
  width:80%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  line-height: 19px;
  padding-top: 1px;
  background-color: transparent !important;
}
.background-not-clickable{
  width:100%;
  height:100%;
  position:fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  background-color: #474747a1;
}

.bi-eye{
  color: #000;

}
.bi-eye:hover{
  color:#00d524f4
}
.content_showing{
  background-color: #ffffff;
  width:90%;
  height: 100vh;
  margin: auto;
  border-radius: 27px;
  
  /* width: 50%; */
}
#fileContent{
  display: flex;
  font-family: sans-sherif;
  scrollbar-width: thin; /* Set the width of the scrollbar */
  overflow-y: auto;
  width:100%;
  /* align-items: center;
  justify-content: center; */
  padding: 50px;
  height: 88vh;
  /* background-color: #4e4e4e21; */


}
.swal2-close:focus{
  box-shadow: inset 0 0 0 3px rgb(255, 255, 255);
}
/* .CloseButton{
  background-color: #00000000;
} */
.ImageShowingStyle{
  width: auto;
  /* width:10%; */
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* padding: 50px; */
  z-index: -1;

  
}
.image-size{
  width:90%;
  height:auto;
}
.image-hide{
  position: absolute;
  z-index: 999;
  width:90%;
  color: transparent; 
  height: 100vh;;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: -350px; */
}
/* video::-internal-media-controls-download-button {
  display:none;
 }

 video::-webkit-media-controls-enclosure {
      overflow:hidden;
 }

 video::-webkit-media-controls-panel {
      width: calc(100% + 30px); 
 } */
/* .image-hide2{
  position: relative;
  z-index: 999;
  width:auto;
  color: transparent; 
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -28rem;
} */
.pdf-container{
  width:100%;
  height:90px;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}


.premimum{
  left:7.3%;
  margin-top:8px;
}


.emailList:hover{
   background-color: #7b7a7a31;
   cursor: pointer;
}

/* .view_style_button{
  display: block;
  width:430px !important;
  padding: 15px;
  margin-left: 17px;
  background-color: #8987873f;
  color:#000000;
  border-radius: 13px;
}
.dropDown_Item{
  width:430px !important;
}  */



.view_style_button{
  display: block;
  width:590px !important;
  padding: 15px;
  margin-left: 5px;
  background-color: #8987873f;
  color:#000000;
  border-radius: 13px;
}
.dropDown_Item{
  width:590px !important;
} 

/* .permission-drop-down{
  margin-top: -10px;
} 
/* .view_style_button{
  display: block;
  padding: 6.5px 0px 6.5px 0px;
  color:#000000;
  cursor: pointer;
}
.dropDown_Item{
  --bs-dropdown-min-width:100px !important;
} */
.bi-check2{
  color: #284eca;
}
.drop-downItem:hover{
  background: -webkit-linear-gradient( #0073B4 -17.05%, #00054E 100.17%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  /* color:#ff0000 !important;
  background-color: transparent !important; */
}
.collaboration{
  /* height:200px; */
  height: auto;
  max-height: 100px;
  overflow-y: auto;
  scroll-behavior: smooth;
  /* background-color: #ffc8c8; */
}


.email__tag__list ul{
   display: flex;
   flex-wrap: wrap;
   padding-left: 0 !important;
   padding-top: 10px ;
}
.email__tag__list ul li{
  padding-left: 5px;
  margin-top:5px ;
  
}
.email__tag__list ul li a{
  padding: 3px 5px 5px 3px;
  border-radius: 13px 13px 13px 13px;
  font-size: 16px;
  background-color: #c4c4c776;
  /* box-shadow: inset; */
  color: #000;

}
.progress_div{
  width: 90%;
  height: 90%;
  max-width:800px !important;
  max-height: 500px !important;
  background-color: transparent;
  border-radius: 15px;
}
/* comment design */
.own-comment{
  /* background-color: #5e72e4 !important; */
  background-image: linear-gradient(180deg, #3369FF 0%, #001F75 100%) !important;
  color:white;
  border-radius:  20px 20px 0px 20px;
}
.user-comment{
  background-image: linear-gradient(0deg, #CFCFCF 0%, #F0F0F0 100%);
  border-radius:  0px 24.336px 0px 24.34px;
}
.user-name{
font-size: 15px;
font-style: normal;
font-weight: 800;
line-height: normal;
}
.comments{
  width:200px;
  overflow: hidden;
}
.comment-header{
  padding-top:1rem;
  border-radius: 0px 30px 0px 0px;
  background: linear-gradient(180deg, #FFF 0%, #98C1F0 125.84%);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.56) inset;
}
.hederIconStyle{
  background: -webkit-linear-gradient( 121.64deg,  #59EBFF -17.06%, #00A3FF -17.05%, #00054E 100.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 25px;
  padding:50px 0px 20px 5px !important;
}
.hederIconStyleForText{
  background: -webkit-linear-gradient( 121.64deg, #59EBFF -17.06%, #00A3FF -17.05%, #00054E 100.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 25px;
  font-weight: 700;
  padding:50px 0px 20px 5px !important;
}

.commentBoxDiv {
  /* margin-top: ; */
  border-radius: 62px;
  border: 2px solid #72A6E5;
  background: #FFF;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25) inset;
  display: flex;
  /* width: 16rem; */
  padding: 8px;
  
  
}
.commentBox:focus{
  outline: transparent !important;
}
.commentBox {
  background-color: transparent;
  border: none;
  padding-left: 5px;
  width: 19rem;
}
.commentBoxBgDiv{
/* border-radius: 0px 30px 30px 0px; */
background: linear-gradient(180deg, #F6F6F6 20.83%, #E9E9E9 100%);
box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.56) inset;
}
.comment_body{
  border-radius: 5px;
  border: 2px solid #D1D1D1;
  background: #FFF;
  /* margin-right:5px; */
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;

}
.Crs_style {
  color: #868686;
font-family: Nunito;
font-size: 17.216px;
font-style: normal;
font-weight: 700;
line-height: normal;

}
.Crs_styleDiv{
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.text-box-div{
  margin-left: 7px;
  margin-right: 32px;
}
.text-box{
  border-radius: 15px !important;
}









/* pricing  */
.pricing-bg-color{

  background-color:hsla(202,91%,64%,1);
  background-image:
  radial-gradient(at 75% 98%, hsla(197,100%,51%,1) 0px, transparent 50%),
  radial-gradient(at 94% 81%, hsla(220,100%,43%,1) 0px, transparent 50%),
  radial-gradient(at 42% 89%, hsla(231,100%,39%,1) 0px, transparent 50%),
  radial-gradient(at 100% 15%, hsla(229,100%,36%,1) 0px, transparent 50%),
  radial-gradient(at 36% 10%, hsla(195,100%,50%,0.95) 0px, transparent 50%),
  radial-gradient(at 0% 75%, hsla(229,100%,38%,1) 0px, transparent 50%);
  display: grid;
  place-items: center;
  font-family: "League Spartan", system-ui, sans-serif;
  font-size: 1.1rem;
  line-height: 1.2;
  color: #ddd;
  margin-bottom: 25px !important;
}

@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;500;600;700;800;900&display=swap");



.main {
  max-width: 75rem;
  padding: 3em 1.5em;
}

.main__heading {
  font-weight: 600;
  font-size: 2.25em;
  margin-bottom: 0.75em;
  text-align: center;
  color: #eceff1;
}

.cards {
  position: relative;
}

.cards__inner {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5em;
}

.cardz {
  --flow-space: 0.5em;
  --hsl: var(--hue), var(--saturation), var(--lightness);
  flex: 1 1 14rem;
  padding: 1.5em 2em;
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  align-items: start;
/*   gap: 1.25em; */
  color: #eceff1;
  background-color: rgba(0,6,95,0.5);
  border: 2px solid #eceff133;
  border-radius: 15px;
  box-shadow: 0px 0px 27px 0px rgba(0,6,95,0.5) inset;
}

.cardz:nth-child(1) {
  --hue: 195;
  --saturation: 100%;
  --lightness: 70%;
}

.cardz:nth-child(2) {
  --hue: 195;
  --saturation: 100%;
  --lightness: 70%;
}

.cardz:nth-child(3) {
  --hue: 195;
  --saturation: 100%;
  --lightness: 70%;
}

.card__bullets {
  line-height: 1.4;
}

.card__bullets li::before {
  display: inline-block;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='16' title='check' fill='%23dddddd'%3E%3Cpath d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z' /%3E%3C/svg%3E");
  transform: translatey(0.25ch);
  margin-right: 1ch;
}

.card__heading {
  font-size: 1.55em;
  font-weight: 700;
  line-height: 45px;
}

.card__subheading {
  font-size: 1.25em;
  font-weight: 600;
}
.card__customsubheading {
  font-size: 1.05em;
  font-weight: 600;
}
.card__price {
  font-size: 1.75em;
  font-weight: 700;
  line-height: 75px;
}

.card__Customprice {
  font-size: 1.15em;
  font-weight: 700;
  color:lightgreen;
  line-height: 75px;
}
.flow > * + * {
  margin-top: var(--flow-space, 1.25em);
}

.cta {
  display: block;
  align-self: end;
  margin: 1em 0 0.5em 0;
  text-align: center;
  text-decoration: none;
  color: #000;
  background-color: #CCDBFF;
  padding: 0.7em;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
}

.overlay {
  position: absolute;
  inset: 0;
  pointer-events: none;
  user-select: none;
  opacity: var(--opacity, 0);
  -webkit-mask: radial-gradient(
    25rem 25rem at var(--x) var(--y),
    #000 1%,
    transparent 50%
  );
  mask: radial-gradient(
    25rem 25rem at var(--x) var(--y),
    #000 1%,
    transparent 50%
  );
  transition: 400ms mask ease;
  will-change: mask;
}

.overlay .cardz {
  background-color: hsla(var(--hsl), 0.15);
  border-color: hsla(var(--hsl), 1);
  box-shadow: 0 0 0 1px inset hsl(var(--hsl));
}


.overlay .cta {
  display: block;
  grid-row: -1;
  width: 100%;
  background-color: hsl(var(--hsl));
  box-shadow: 0 0 0 1px hsl(var(--hsl));
}

:not(.overlay) > .cardz {
  transition: 400ms background ease;
  will-change: background;
}

:not(.overlay) > .cardz:hover {
  --lightness: 95%;
  background: hsla(var(--hsl), 0.1);
}