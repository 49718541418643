@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300&display=swap');
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.logo-img{
    height: auto;
    margin-left: 20px;
    margin-top: 10px;
    width: 180px;
}
.navbar-nav li a:hover{
    /* border-bottom:  #fffcfc 1px solid; */
    color: #656666 !important;
}
.navbar-nav li a{
    font-size: 16px ;
    text-transform: capitalize;
    padding: 15px 5px 15px 10px;
    font-family: 'Inter';
    
    font-weight: bold;
    line-height: 23.27px;
    color: #000;
    
}
.offcanvas-body{
    padding: 0 !important;
}
.btn-close{
    right: 0;
    /* margin-top: 50px; */
    margin: 30px 20px 20px 25px !important;
}
.button-hover_none:hover{
    border-color: transparent !important;
  }
.bg-image{
  
    background-image: url('https://res.cloudinary.com/derifbjbg/image/upload/v1686735994/home-page-banner_q7qgpe.png');
    object-fit: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
}
.layer-1{
    position: absolute;
    width: 1920px;
    height: 912px;
    left: 2px;
    top: 14px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 1.92%, rgba(255, 255, 255, 0.34) 50.96%, #FFFFFF 100%);
}
/* .nav-link{
    background: linear-gradient(0deg, #000258 0%, #0D74EC 100%);
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    background-clip: text !important;
    font-family: 'Inter', sans-serif;
} */


.nav-items{
  padding-right: 10px;
} 
/* header{
    z-index:9999 ;
} */
.header-position{
    right:2%;
    position: absolute;

}

  .image-position{
        margin-top: -180px;
        position: absolute;
        /* bottom: 0; */
        object-fit: cover;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100%;

  }
  .upload_button{
    margin-top: -100px;
     position: absolute; 
     object-fit: cover; 
     background-repeat: no-repeat;
      max-width: 20rem; 
      height: auto; 
      margin-bottom: 100px !important; 
      left: 40.0%;
      cursor: pointer;
      
  }
/* .bi-list{
  color:#f2f2f2;
  
} */
.button-hover_none:hover{
  border-color: transparent !important;
}
.button-hover_none:focus{
  box-shadow:0 0 0 0 !important;
}
  .hero {
    margin-top: -79.5px !important;
    position: relative;
   
} 
.color_style{
background: linear-gradient(121.64deg, #59EBFF -17.07%, #59EBFF -17.06%, #00A3FF -17.05%, #00054E 100.17%);
/* box-shadow: inset 0px 20px 14px rgba(0, 0, 0, 0.25) !important; */
}
 .card-feature{
    margin: auto;
    border: 1px solid #e2e0e0 !important;
    width: 90%;

  }
  .upper-sec{
    height: 90vh;
    border-radius: 0px 0px 135px 135px;
    /*background-image: url('https://res.cloudinary.com/dgx4bwfhd/image/upload/v1678094663/fabio-oyXis2kALVg-unsplash_mcosjf.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;*/
    background-color: #3475e1;
  }
  .logo-main{
    width: 200px;
  }
  .my-up-sec{
    margin: auto;
    width: 80%;
    margin-top:-300px ;
  }
  .my-up-div{
    display: flex;
  }
  .upblock{
    max-height: 500px;
    width:45%;
    margin-right:10%;
    background: #ffffff;
    box-shadow:  2px 2px 4px #bfbfbf,
                 2px 2px 4px #ffffff;
  }
  .dashboard-block{
    height: 500px;
    width: 45%;
    background: #ffffff;
    box-shadow:  2px 2px 4px #bfbfbf,
                 2px 2px 4px #ffffff;
  }

  /* .row{
    margin-right:0px!important;
  } */
  .bg-cyan{
    background-color: #c6eff2;
  }
  .ubuntu{
    font-family: 'Inter', sans-serif;
  }
  .f-40{
    font-size: 40px;
    font-weight: 700;
  }
  .f-20{
    font-size: 20;
  }
  .highlight{
    color:#3475e1;
  }
  .textNormal{
    text-align: justify;
    padding-right:10px;
    font-size: 16px;
  }
  .bg-img-left{
    background-image: url('../../components/Images/3d\ internet\ secuirty\ badge.png');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .bg-img-right{
    background-image: url('../../components/Images/dataStorage.svg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .newslater{
    background-color: #1f1f1f;
    
  }
  .news-form{
    margin-top: 70px;
  }
  .news-box{
    width:70%;
    height:40px;
    padding-left: 10px;
    margin-right: 5px;
  }
  .subs-btn{
    height: 39px;
    font-size: 18px;
    color: #fff;
    padding: 0px 20px;
    border: none;
    background-color: #3475e1;
  }
  .subs-btn:hover{
    background-color: #176eff;
  }
  .card-feature{
    margin: auto;
    border: 1px solid #f2f2f2;
    width: 90%;

  }
  .ico-features{
    
    width: 60px;
    height: auto;
    margin-top:15px ;
  }
  /* button:hover{
    border: transparent !important;
  } */
  /* .upload_box {
    width: 320px;
    height: 320px;
    background-color: red;
    position: relative;
    animation-name: example;
    animation-duration: 4s;
  }
  
  @keyframes example {
    0%{background-color:red; left:0px; top:200px;}
    100%{background-color:red; left:0px; top:0px;}
  }
  */
  .upload_detalis{
    position: absolute;
    margin-top: -320px;
    left: 35%;

  } 
  :root {
    --hue: 223;
    --bg: hsl(var(--hue),10%,85%);
    --fg: hsl(var(--hue),10%,5%);
    --trans-dur: 0.3s;
  }

  .modals {
    background-color: hsl(var(--hue),10%,95%);
    border-radius: 1em;
    box-shadow: 0 0.75em 1em hsla(var(--hue),10%,5%,0.3);
    color: hsl(var(--hue),10%,5%);
    width: calc(100% - 3em);
    max-width: 34.5em;
    overflow: hidden;
    position: relative;
    transition:
      background-color var(--trans-dur),
      color var(--trans-dur);
  }
  .modals:before {
    background-color: hsl(223,90%,60%);
    border-radius: 50%;
    content: "";
    filter: blur(60px);
    opacity: 0.15;
    position: absolute;
    top: -8em;
    right: -15em;
    width: 25em;
    height: 25em;
    z-index: 0;
    transition: background-color var(--trans-dur);
  }
  .modal__actions {
    animation-delay: 0.2s;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .modal__body,
  .modal__header {
    position: relative;
    z-index: 1;
  }
  .modal__body {
    display: flex;
    flex-direction: column;
    padding: 0 2em 1.875em 1.875em;
  }
  .modal__button,
  .modal__close-button {
    color: currentColor;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  .modal__button {
    background-color: hsla(var(--hue),10%,50%,0.2);
    border-radius: 0.25rem;
    font-size: 0.75em;
    /* padding: 0.5rem 2rem; */
    transition:
      background-color var(--trans-dur),
      border-color var(--trans-dur),
      opacity var(--trans-dur);
    width: 100%;
  }
  .modal__button + .modal__button {
    margin-top: 0.75em;
  }
  .modal__button:disabled {
    opacity: 0.5;
  }
  .modal__button:focus,
  .modal__close-button:focus {
    outline: transparent;
  }
  .modal__button:hover,
  .modal__button:focus-visible {
    background-color: hsla(var(--hue),10%,60%,0.2);
  }
  .modal__button--upload {
    background-color: transparent;
    border: 0.125rem dashed hsla(var(--hue),10%,50%,0.4);
    flex: 1;
    padding: 0.375rem 2rem;
  }
  .modal__col + .modal__col {
    flex: 1;
    margin-top: 1.875em;
  }
  .modal__close-button,
  .modal__message,
  .modal__progress-value {
    color: hsl(var(--hue),10%,30%);
    transition: color var(--trans-dur);
  }
  .modal__close-button {
    background-color: transparent;
    display: flex;
    width: 1.5em;
    height: 1.5em;
    transition: color var(--trans-dur);
  }
  .modal__close-button:hover,
  .modal__close-button:focus-visible {
    color: hsl(var(--hue),10%,40%);
  }
  .modal__close-icon {
    display: block;
    margin: auto;
    pointer-events: none;
    width: 50%;
    height: auto;
  }
  .modal__content > * {
    /* don’t use shorthand syntax, or actions delay will be overridden */
    animation-name: fadeSlideIn;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    opacity: 0;
  }
  .modal__file {
    flex: 1;
    font-size: 0.75em;
    font-weight: 700;
    margin-right: 0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .modal__file ~ .modal__button {
    margin-top: 1.5em;
  }
  .modal__file-icon {
    color: hsl(var(--hue),10%,50%);
    display: block;
    margin-right: 0.75em;
    width: 1.5em;
    height: 1.5em;
    transition: color var(--trans-dur);
  }
  .modal__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 2.5em;
    padding: 0.5em;
  }
  .modal__icon {
    display: block;
    margin: auto;
    width: 2.25em;
    height: 2.25em;
  }
  .modal__icon--blue g {
    stroke: hsl(223,90%,50%);
  }
  .modal__icon--red g {
    stroke: hsl(3,90%,50%);
  }
  .modal__icon--green g {
    stroke: hsl(138,90%,40%);
  }
  .modal__icon circle,
  .modal__icon line,
  .modal__icon polyline {
    animation: sdo 0.25s ease-in-out forwards;
    transition: stroke var(--trans-dur);
  }
  .modal__icon :nth-child(2) {
    animation-delay: 0.25s;
  }
  .modal__icon :nth-child(3) {
    animation-delay: 0.5s;
  }
  .modal__icon-sdo10 {
    stroke-dashoffset: 10;
  }
  .modal__icon-sdo14 {
    stroke-dashoffset: 14.2;
  }
  .modal__icon-sdo69 {
    stroke-dashoffset: 69.12;
    transform: rotate(-90deg);
    transform-origin: 12px 12px;
  }
  .modal__message {
    animation-delay: 0.1s;
    font-size: 1em;
    margin-bottom: 1.5em;
    min-height: 3em;
  }
  .modal__progress {
    flex: 1;
  }
  .modal__progress + .modal__button {
    margin-top: 1.75em;
  }
  .modal__progress-bar {
    background-image: linear-gradient(90deg,hsl(var(--hue),90%,50%),hsl(var(--hue),90%,70%));
    border-radius: 0.2em;
    overflow: hidden;
    width: 100%;
    height: 0.4em;
    transform: translate3d(0,0,0);
  }
  .modal__progress-fill {
    background-color: hsl(var(--hue),10%,90%);
    width: inherit;
    height: inherit;
    transition: transform 0.1s ease-in-out;
  }
  .modal__progress-value {
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1.333;
    text-align: right;
  }
  .modal__sr {
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
  }
  .modal__title {
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  /* state change */
  [data-state="2"]:before {
    background-color: hsl(3,90%,60%);
  }
  [data-state="3"]:before {
    background-color: hsl(138,90%,60%);
  }
  .modal__icon + .modal__icon,
  [data-state="1"] .modal__icon:first-child,
  [data-state="2"] .modal__icon:first-child,
  [data-state="3"] .modal__icon:first-child,
  .modal__content + .modal__content,
  [data-state="1"] .modal__content:first-child,
  [data-state="2"] .modal__content:first-child,
  [data-state="3"] .modal__content:first-child {
    display: none;
  }
  [data-state="1"] .modal__icon:first-child,
  [data-state="2"] .modal__icon:nth-child(2),
  [data-state="3"] .modal__icon:nth-child(3),
  [data-state="1"] .modal__content:nth-child(2),
  [data-state="2"] .modal__content:nth-child(3),
  [data-state="3"] .modal__content:nth-child(4) {
    display: block;
  }
  [data-ready="false"] .modal__content:first-child .modal__actions:nth-of-type(2),
  [data-ready="true"] .modal__content:first-child .modal__actions:first-of-type {
    display: none;
  }
  [data-ready="true"] .modal__content:first-child .modal__actions:nth-of-type(2) {
    display: flex;
  }
  
  /* Dark theme */
  @media (prefers-color-scheme: dark) {
    :root {
      --bg: hsl(var(--hue),10%,35%);
      --fg: hsl(var(--hue),10%,95%);
    }
    .modal {
      background-color: hsl(var(--hue),10%,10%);
      color: hsl(var(--hue),10%,95%);
    }
    .modal__close-button,
    .modal__message,
    .modal__progress-value {
      color: hsl(var(--hue),10%,70%);
    }
    .modal__close-button:hover,
    .modal__close-button:focus-visible {
      color: hsl(var(--hue),10%,80%);
    }
    .modal__file-icon {
      color: hsl(var(--hue),10%,60%);
    }
    .modal__icon--blue g {
      stroke: hsl(223,90%,60%);
    }
    .modal__icon--red g {
      stroke: hsl(3,90%,60%);
    }
    .modal__icon--green g {
      stroke: hsl(138,90%,60%);
    }
    .modal__progress-fill {
      background-color: hsl(var(--hue),10%,20%);
    }
  }
  
  /* Animations */
  @keyframes fadeSlideIn {
    from { opacity: 0; transform: translateY(33%); }
    to { opacity: 1; transform: translateY(0); }
  }
  @keyframes sdo {
    to { stroke-dashoffset: 0; }
  }
  
  /* Beyond mobile */
  @media (min-width: 768px) {
    .modal__actions--center {
      justify-content: center;
      margin-left: -4.125em;
    }
    .modal__body {
      flex-direction: row;
      align-items: center;
    }
    .modal__button {
      width: auto;
    }
    .modal__button + .modal__button {
      margin-top: 0;
      margin-left: 1.5rem;
    }
    .modal__file ~ .modal__button {
      margin-top: 0;
    }
    .modal__file ~ .modal__close-button {
      margin-right: 1.5rem;
    }
    .modal__progress {
      margin-right: 2em;
    }
    .modal__progress + .modal__button {
      margin-top: 0;
    }
    .modal__col + .modal__col {
      margin-top: 0;
      margin-left: 1.875em;
    }
    .modal__title {
      text-align: left;
    }
  }


.scroll-div{
  max-height: 90px;
}


.scroll-div{
  max-height: 90px;
}

.main-hero{
  width: 100%;
  max-height: 100%;
  height: 90vh;
  
  background: linear-gradient(172.06deg, #00B3FF -100.86%, #FFFFFF 61.02%);
  /* background-image: url('https://res.cloudinary.com/derifbjbg/image/upload/v1686735994/home-page-banner_q7qgpe.png'); */
  background-position: center center;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0px 0px 150px 150px;
}
.bg-body-custom{
  background: #FFFFFF;
  box-shadow: 0px 4px 31px 5px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(125px);
}
.logo-home{
  /* width: 180px; */
  height: auto;
  margin-left: 10px;
  width: 170px;
  height: 40px;
}

.my-custom-card{
  width: 100%;
  max-width: 16.75rem;
  height:17.625rem;
  border-radius: 20px;
  background-color: #fff;
  border: 2px solid #FFFFFF;
  box-shadow: 4px 4px 22px 4px rgba(0, 0, 0, 0.25);
  margin-top: 150px;
  margin-left: 30px;
  text-align: center;
  
}



.my-custom-card1{
  width: 100%;
  max-width: 16.75rem;
  height:17.625rem;
  border-radius: 20px;
  background-color: #fff;
  border: 2px solid #FFFFFF;
  box-shadow: 4px 4px 22px 4px rgba(0, 0, 0, 0.25);
  margin-top: 150px;
  margin-left: 10px;
  text-align: center;
  
}
.heading{
  
  text-align: center;
  font-weight: 600;
  background: -webkit-linear-gradient( #0073B4 -17.05%, #00054E 100.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Heading_div{
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 3.5rem;
background: #FFFFFF;
border: 2px solid #FFFFFF;
box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.10);
border-radius: 13px;
}


.upload-image{
  width: 100px;
  cursor: pointer;
  
}
.upload-bucket{
  width: 70px;
  cursor: pointer;  
}
div.container-l {
  margin-top: 20px;
  text-align: center;
}

ul.myUL {
  display: inline-block;
  text-align: left;
  margin-left: 10px !important;
  
}
ul.myUL li{
  font-size: 14px;
  font-weight:600;
}

.circle{
  width:15px ;
  height: 15px ;
  border-radius: 50% 50% 50% 50%;
  background: linear-gradient(121.64deg, #59EBFF -17.07%, #59EBFF -17.06%, #00A3FF -17.05%, #00054E 100.17%);
}
.send_now_btn{
  padding: 13px;
  border-radius: 0px 50px 50px 0px;
  color:#ffffff;
  background: linear-gradient(121.64deg, #59EBFF -17.07%, #59EBFF -17.06%, #00A3FF -17.05%, #00054E 100.17%);
  box-shadow: 0px 4px 31px 5px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(125px);
}
.access_now{
  max-width: 100%;
  padding: 13px;
  border-radius: 0px 50px 50px 0px;
  color:#ffffff;
  background: linear-gradient(121.64deg, #59EBFF -17.07%, #59EBFF -17.06%, #00A3FF -17.05%, #00054E 100.17%);
  box-shadow: 0px 4px 31px 5px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(125px);
}
.AccessBucket{
  background: linear-gradient(121.64deg, #59EBFF -17.07%, #59EBFF -17.06%, #00A3FF -17.05%, #00054E 100.17%) !important;
  display: flex;
  text-align: center;
  color: #fff;

}
.access_buttom{
  margin-left: -15px;
  margin-top: 10px;
}
.blue_div{
height: 118vh;
background: linear-gradient(121.64deg, #59EBFF -17.06%, #00A3FF -17.05%, #00054E 100.17%);
margin-top: -50px;
;
}
.Features{
  height: 28vh;
  /* margin-top: 230px; */
  justify-content: center;
  align-items: center;
}
.featuresIcon{
  display: inline block;
  margin-left: 20px;

}
.featuresIcon span{
  font-size: 16px;
  color: white;
  font-weight: 500;
}
.Hero_text{
  background: #DEEFFF;
  justify-content: center;
  align-items: center;
}
.Hero_text h1{
  padding-top: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 42px;
line-height: 75px;
/* or 136% */
text-align: center;
text-transform: capitalize;
background: linear-gradient(0deg, #000258 0%, #0D74EC 100%);
-webkit-background-clip: text !important;
-webkit-text-fill-color: transparent !important;
background-clip: text !important;
font-family: 'Inter', sans-serif;
/* Linear */


}
.Hero_text p{
  font-size: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 50px 50px 20px;
  font-weight: 500;
  text-align: justify;

}
.footer-item a{
font-size: 16px !important;
padding-top: 5px !important;

}
.footer-item{
  font-size: 16px;
  padding-top: 5px;
}
.bgImageRight{
  position: absolute;
  max-width: 600px;
  width: 100%;
  height: auto;
  
  right:1%;
  top:20%;
}
/* @media screen and ( max-width:1092px){
  .ImageRight_div{
    display: none !important;
  }
} */

@media screen and (min-width:1191px) and ( max-width:1248px){
  .bgImageRight{
    width: 550px;
    height: auto;
  }
}
@media screen and (min-width:1086px) and ( max-width:1191px){
  .bgImageRight{
    width: 500px;
    height: auto;
    right:0%;
  }
}
@media screen and (min-width:991px) and ( max-width:1086px){
  .bgImageRight{
    width:430px;
    height: auto;
    right:0%;
  }
}
@media screen and (min-width:991px) and ( max-width:1086px){
  
}
.mobile-menu{
  background-color: #fafafa;
  box-shadow: 10px 10px 10px 10px  rgba(0,0,0,.25);
  bottom: 0%;
  border-radius: 17px 17px 0 0;
  color: #080707;
  width:100%
 

}
.mobile-menu li{
  padding: 10px;
  text-align: left;
  cursor: pointer;
  
}
.mobile-menu li a{
  font-size: 18px;
  font-weight: 500;
  padding-left: 1rem;

}
.mobile-menu li:hover{
 background: linear-gradient(121.64deg, #59EBFF -17.06%, #00A3FF -17.05%, #00054E 100.17%)
}
.mobile-menu li a:hover{
  color:#ffffff !important;
}
.child{
  width:100%;
  height:auto;
  position:fixed;
  bottom: 0;
  border-radius: 17px 17px 0 0;
  display: flex;
  flex-direction: column;
  z-index: 250;
  
}
.cross{
 
  /* background: aqua; */
  border-radius: 50px;
  float:right;
  height: 25px;

}
div.close-bottom{
  position: absolute;
  right:10px;
  margin-top: -50px;;
  padding: 5px 12px 10px 12px;
  background-color: #fafafa;
  border-radius: 50px;
  cursor: pointer;
}
.navigation{
  padding-left: 0 !important;
  margin-top: 1rem !important;
}
.background-not-clickable{
  width:100%;
  height:100%;
  position:fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  background-color: #1313134b;
}

@media screen and ( min-width:575px) and ( max-width:991px){
  .my-custom-card{
    margin:80px auto 0px auto !important;
  }
  .my-custom-card1{
    margin:80px auto 0px auto !important;
  }
}
@media screen and ( max-width:575px){
  .main-hero{
    width: 100%;
    max-height: 100%;
    height: 100%;
    background: linear-gradient(172.06deg, #00B3FF -100.86%, #FFFFFF 61.02%);
    border-radius: 0px 0px 0px 0px;
  }
  .Features{
    display: none;
  }
  .Hero_text p{
    padding: 20px 20px 20px 20px;
  }
  .container_text{
    padding: 5px 5px 5px 5px;
  }
  .my-custom-card{
    margin:80px auto 0px auto;
  }
  .my-custom-card1{
    margin:50px auto 0px auto;
  }
}
@media screen and ( max-width:575px){
  /* ul.myUL{
    display: none !important;
  } */
  /* .my-custom-card1{
    width: 100%;
    max-width: 16.75rem;
    height:10.625rem;
   
    
  }
  .my-custom-card{
    width: 100%;
    max-width: 16.75rem;
    height:10.625rem;
   
    
  } */
}





.footer-sec {

  background: linear-gradient(180deg, #E7F7FF 0%, #C0EBFF 94.27%);
  /* box-shadow: 0px -1px 78px 0px rgba(0, 0, 0, 0.25); */
}

.copyright {
  background: rgba(255, 255, 255, 0.55);
  backdrop-filter: blur(130px);
}

.facebook-icon {
  color: #000000;
}

.facebook-icon:hover {
  color: #1979ff;
}

.twitter-icon {
  color: #000000;
}

.twitter-icon:hover {
  color: #4bd4fd;
}

.link-icon {
  color: #000000;
}

.link-icon:hover {
  color: #2f5ae6;
}

.youtube-icon {
  color: #000000;
}

.youtube-icon:hover {
  color: #ee1f1f;
}

.Insta-Icon {
  color: #000000;
}

.Insta-Icon:hover {
  color: #ff44a1;
}

.marquee,
.marquee-with-options,
.marquee-vert-before,
.marquee-vert {
  width: 300px;
  overflow: hidden;
}
.footer-address{
  margin-top: 19px;
}
.footer-address a {
  font-size: 13px;
  color: #808080;

}

.footer-address a:hover {
  color: #000;
}

.footer-address-img {
  height: 185px;
  border-radius: 13px;
}

.footer-ico {
  font-size: 13px;
}

.footr-img-1 {
  width: auto;
  height: 30px;
}

.icon-pad {
  padding-left: 18px;
}

.product.ms-5 {
  margin-left: 6rem !important;
}

.resource.ms-5 {
  margin-left: 6rem !important;
}

.solution.ms-5 {
  margin-left: 6rem !important;
}

@media (max-width:1200px) {
  .footer-address-img {
      height: 132px;
      width: auto;
  }

}

@media (max-width:1200px) {
  .footer-address {
      font-size: 9px;
  }

  .product.ms-5 {
      margin-left: 3rem !important;
  }

  .resource.ms-5 {
      margin-left: 3rem !important;
  }

  .solution.ms-5 {
      margin-left: 3rem !important;
  }
}


@media (max-width: 769px) {
  /* .col-sm-6 {
      flex: 0 0 auto;
      width: 100%;
      margin-top: 40px;
  } */

  .product.ms-5 {
      margin-left: unset !important;
  }

  .resource.ms-5 {
      margin-left: 9rem !important;
  }

  .solution.ms-5 {
      margin-left: 9rem !important;
  }
}


@media (max-width:500px) {
  /* .col-sm-6 {
      flex: 0 0 auto;
      width: 100%;
      margin-top: 40px;
  } */

  .product.ms-5 {
      margin-left: unset !important;
  }

  .resource.ms-5 {
      margin-left: 2rem !important;
  }

  .solution.ms-5 {
      margin-left: 2rem !important;
  }

  .footer-address-img {
      height: 92px;
      width: auto;
  }


}


@media (max-width:395px) {

  .icon-pad {
      padding-left: 7px !important;
  }

  .footr-img-1 {
      height: 20px !important;
  }

  .footer-address-img {
      height: 80px !important;
  }

  .copyright {
      font-size: 15px !important;
  }

}


@media (max-width:322px) {

  .icon-pad {
      padding-left: 4px !important;
  }

  .footr-img-1 {
      height: 15px !important;
  }

  .footer-address-img {
      height: 65px !important;
  }


  .resource.ms-5 {
      margin-left: 0.8rem !important;
  }

  .solution.ms-5 {
      margin-left: 0.8rem !important;

  }


  .footer-title {
      font-size: 20px !important;
  }

  .footer-address {
      font-size: 7px !important;
  }

  .text-padding.p-5 {
      padding: 2rem !important;
  }}